var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex _bg-default _80w _100vh"},[(_vm.loading)?_c('div',{staticClass:"_100top pa-3 _full-w"},[_c('h3',[_vm._v("Loading ...")])]):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"_100top pa-3 _full-w"},_vm._l((_vm.vacancies.data),function(item){return _c('v-card',{key:item.id,staticClass:"radius-card width__thread pa-3"},[_c('div',[_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            arrow: true,
            arrowType: 'round',
            animation: 'fade',
            theme: 'light',
            maxWidth: 100
          }),expression:"{\n            arrow: true,\n            arrowType: 'round',\n            animation: 'fade',\n            theme: 'light',\n            maxWidth: 100\n          }"}],attrs:{"color":"green","fab":"","outlined":"","small":"","to":"/psikolog/vacancies","content":"back"}},[_c('v-icon',[_vm._v("mdi-reply")])],1)],1),_c('div',{staticClass:"sticky_elmnt"},[_c('div',{staticClass:"mt-3 d-flex"},[_c('div',{staticClass:"mr-5"},[_c('img',{attrs:{"src":(_vm.env + "/vacancies/" + (item.logo)),"height":"100px","alt":"log-company"}})]),_c('section',{staticClass:"mb-1 _full-w"},[_c('h1',{staticClass:"blue--text min__"},[_vm._v(_vm._s(item.judul))]),_c('p',[_vm._v(" at "),_c('b',[_vm._v(_vm._s(item.perusahaan))])]),_c('div',{staticClass:"d-flex justify-end"},[(_vm.role == 3 && item.user_id != _vm.id)?_c('div',[(!_vm.loadApply)?_c('v-btn',{staticClass:"mr-3",attrs:{"dark":"","color":"blue"},on:{"click":function($event){return _vm.apply(item.id)}}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Apply Now! ")],1):_vm._e()],1):_vm._e(),(_vm.loadApply)?_c('v-btn',{staticClass:"mr-3",attrs:{"dark":"","color":"blue"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","color":"white"}}),_vm._v(" Loading ")],1):_vm._e(),(item.user_id == _vm.id)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100
                }),expression:"{\n                  arrow: true,\n                  arrowType: 'round',\n                  animation: 'fade',\n                  theme: 'light',\n                  maxWidth: 100\n                }"}],staticClass:"mr-3 mb-2",attrs:{"dark":"","color":"green","content":"Edit"},on:{"click":function($event){return _vm.editVacant(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1):_vm._e(),(item.user_id == _vm.id)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100
                }),expression:"{\n                  arrow: true,\n                  arrowType: 'round',\n                  animation: 'fade',\n                  theme: 'light',\n                  maxWidth: 100\n                }"}],attrs:{"dark":"","color":"red","content":"delete"},on:{"click":function($event){return _vm.delVacant(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1):_vm._e()],1)])]),_c('div',{staticClass:"grey lighten-4 pa-3 rounded"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',[_c('h4',[_vm._v("Location")]),_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(item.relationships[0].kota1.data.nama_kota)+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',[_c('h4',[_vm._v("Category")]),_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.nama_kategori))])])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('div',[_c('h4',[_vm._v("Expired in")]),_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.$date(item.end_date).format("dddd, MMMM, YYYY"))+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('div',[_c('h4',[_vm._v("Posted")]),_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.$date(item.created_at).fromNow()))])])])],1)],1)]),_c('div',{staticClass:"mt-3 pa-3"},[_c('p',{domProps:{"innerHTML":_vm._s(item.deskripsi)}})]),_c('v-card-actions')],1)}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }