<template>
  <div class="d-flex _bg-default _80w _100vh">
    <div v-if="loading" class="_100top pa-3 _full-w">
      <h3>Loading ...</h3>
    </div>
    <div class="_100top pa-3 _full-w" v-if="!loading">
      <v-card
        class="radius-card width__thread pa-3"
        v-for="item in vacancies.data"
        :key="item.id"
      >
        <div>
          <v-btn
            color="green"
            fab
            outlined
            small
            to="/psikolog/vacancies"
            content="back"
            v-tippy="{
              arrow: true,
              arrowType: 'round',
              animation: 'fade',
              theme: 'light',
              maxWidth: 100
            }"
            ><v-icon>mdi-reply</v-icon></v-btn
          >
        </div>
        <div class="sticky_elmnt">
          <div class="mt-3 d-flex">
            <div class="mr-5">
              <img
                :src="`${env}/vacancies/${item.logo}`"
                height="100px"
                alt="log-company"
              />
            </div>
            <section class="mb-1 _full-w">
              <h1 class="blue--text min__">{{ item.judul }}</h1>
              <p>
                at <b>{{ item.perusahaan }}</b>
              </p>
              <div class="d-flex justify-end">
                <div v-if="role == 3 && item.user_id != id">
                  <v-btn
                    v-if="!loadApply"
                    class="mr-3"
                    dark
                    color="blue"
                    @click="apply(item.id)"
                  >
                    <v-icon>mdi-check</v-icon>
                    Apply Now!
                  </v-btn>
                </div>
                <v-btn v-if="loadApply" class="mr-3" dark color="blue">
                  <v-progress-circular
                    indeterminate
                    size="20"
                    color="white"
                  ></v-progress-circular>
                  Loading
                </v-btn>

                <v-btn
                  v-if="item.user_id == id"
                  dark
                  class="mr-3 mb-2"
                  color="green"
                  content="Edit"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100
                  }"
                  @click="editVacant(item.id)"
                >
                  <v-icon>mdi-pencil</v-icon>
                  Edit
                </v-btn>

                <v-btn
                  v-if="item.user_id == id"
                  dark
                  color="red"
                  content="delete"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100
                  }"
                  @click="delVacant(item.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                  Delete
                </v-btn>
              </div>
            </section>
          </div>
          <div class="grey lighten-4 pa-3 rounded">
            <v-row>
              <v-col cols="12" md="4">
                <div>
                  <h4>Location</h4>
                  <p class="ma-0">
                    {{ item.relationships[0].kota1.data.nama_kota }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div>
                  <h4>Category</h4>
                  <p class="ma-0">{{ item.nama_kategori }}</p>
                </div>
              </v-col>
              <v-col cols="12" md="2">
                <div>
                  <h4>Expired in</h4>
                  <p class="ma-0">
                    {{ $date(item.end_date).format("dddd, MMMM, YYYY") }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" md="2">
                <div>
                  <h4>Posted</h4>
                  <p class="ma-0">{{ $date(item.created_at).fromNow() }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="mt-3 pa-3">
          <p v-html="item.deskripsi"></p>
        </div>
        <v-card-actions> </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "vacanciesdetail",
  computed: {
    ...mapState({
      vacancies: state => state.vacancies.vacantID,
      env: state => state.API_URL,
      role: state => state.role,
      id: state => state.id
    })
  },
  data() {
    return {
      loading: false,
      loadApply: false,
      content: "",
      dataComment: null,
      dialogComment: false
    };
  },
  mounted() {
    this.fetchVacant();
  },
  methods: {
    apply(id) {
      this.loadApply = true;
      this.$store.dispatch("vacancies/applyVacancies", id).then(data => {
        this.loadApply = false;
        Swal.fire({
          icon: "success",
          title: data.message,
          showConfirmButton: false,
          position: "top-end",
          toast: true,
          timer: 3000
        }).then(() => {
          this.$router.push("/psikolog/my-application");
        });
      });
    },
    fetchVacant() {
      this.loading = true;
      let id = this.$route.params.id;
      this.$store.dispatch("vacancies/getVacant", id).then(() => {
        this.loading = false;
      });
    },
    delVacant(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("vacancies/deleteVacancies", id).then(() => {
            this.$router.push(`/psikolog/vacancies`);
            Swal.fire({
              icon: "success",
              title: "deleted!",
              showConfirmButton: false,
              position: "top-end",
              toast: true,
              timer: 3000
            });
          });
        }
      });
    },
    editVacant(id) {
      this.$router.push(`/vacancies/${id}/edit`);
    }
  }
};
</script>

<style scoped>
/*  */
.sticky_elmnt {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  background: white;
}
.width__thread {
  position: relative;
  width: 100%;
  box-sizing: border-box !important;
}
@media (max-width: 576px) {
  .width__thread {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .width__thread {
    width: 100%;
  }
}
</style>
